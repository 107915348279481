import Api from '../common/api/Api'

export default class CityService extends Api {
  constructor () {
    super({domain: 'city'})
  }

  async getActive () {
    return this.get({route: 'active', cache: 'citiesActive'})
  }

  async getAvailable (data) {
    return this.post({route: 'available', data})
  }

  async searchAutocomplete (data) {
    return this.post({route: 'autocomplete', data})
  }
}
