<script>
import EmptyList from '../../../components/general/EmptyList'
import LoadingContent from "../../../components/general/placeholders/LoadingContent"
import ScheduleStatus from './ScheduleStatus'

import ScheduleApiService from '../../../services/domains/Schedule'
import CityApiService from "../../../services/domains/City"
import ClubApiService from "../../../services/domains/Club"

import helpers from '@mixins/helper'
import bus from '@utils/bus'
import {mapActions, mapGetters} from 'vuex'

import moment from "moment"
import DataService from "../../../services/common/data"
import TransactionPaymentModal from "../../../components/general/TransactionPaymentModal"

const scheduleService = ScheduleApiService.build({})
const cityService = CityApiService.build({})
const clubService = ClubApiService.build({})

export default {
  mixins: [helpers],
  components: {TransactionPaymentModal, LoadingContent, EmptyList, ScheduleStatus},
  data() {
    return {
      isLoadingData: true,
      isLoadingCities: true,
      isLoadingClubs: false,
      filters: {
        dates: [moment().format('YYYY-MM-DD'), moment().add('days', 7).format('YYYY-MM-DD')],
        club_id: '',
        status: 'scheduled'
      },
      dependencies: {
        cities: [],
        clubs: [],
        schedule_status: []
      },
      showModalDatePicker: false,
      data: {
        headers: [
          {text: this.$t('app.domains.schedule.components.list.table.headers.datetime'), value: 'start_datetime'},
          {text: this.$t('app.domains.schedule.components.list.table.headers.club'), value: 'court.club.name'},
          {text: this.$t('app.domains.schedule.components.list.table.headers.city'), value: 'court.club.city.name'},
          {text: this.$t('app.domains.schedule.components.list.table.headers.court'), value: 'court.name'},
          {text: this.$t('app.domains.schedule.components.list.table.headers.status'), sortable: false}
        ],
        list: []
      },
      transaction: null,
      showPixModal: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'city', 'club']),
    filtersDates: {
      get() {
        if (this.filters.dates.length > 0) {
          if (this.filters.dates.length === 1) {
            return moment(this.filters.dates[0]).format('DD/MM/YYYY')
          } else {
            return 'De ' + moment(this.filters.dates[0]).format('DD/MM/YYYY') + ' até ' + 'De ' + moment(this.filters.dates[1]).format('DD/MM/YYYY')
          }
        }
        return ''
      },
      set(value) {
        if (!value) {
          this.filters.dates.splice(0)
        } else {
          this.filters.dates = [...value]
        }
      }
    }
  },
  async mounted() {
    await this.getCities()
    this.getDependencies()
    if (this.city && this.city.id && this.club && this.club.id) {
      this.filters.city_id = this.city.id
      await this.selectCity(this.filters.city_id)
      this.filters.club_id = this.club.id
    }
    this.loadSchedules()
  },
  methods: {
    ...mapActions(['setCity', 'setClub']),
    getDependencies() {
      DataService.get([{domain: 'schedule_status'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    getCities() {
      return new Promise(resolve => {
        this.isLoadingCities = true
        cityService.getAvailable().then(response => {
          this.dependencies.cities = [...response]
          this.dependencies.clubs.splice(0)
          this.isLoadingCities = false
          resolve()
        })
      })
    },
    getClubs(cityId) {
      return new Promise(resolve => {
        this.isLoadingClubs = true
        const data = {city_id: cityId}
        clubService.getAvailable(data).then(response => {
          this.dependencies.clubs = [...response]
          this.isLoadingClubs = false
          resolve()
        })
      })
    },
    async selectCity(value) {
      this.dependencies.clubs.splice(0)
      if (value) {
        const city = this.dependencies.cities.find(item => item.id === value)
        this.setCity(city)
        await this.getClubs(value)
      }
    },
    selectClub(value) {
      if (value) {
        const club = this.dependencies.clubs.find(item => item.id === value)
        this.setClub(club)
      }
    },
    prepareSearchData() {
      const search = {}
      if (this.filters.dates.length > 0) {
        if (this.filters.dates.length === 1) {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD')
          search.end_date = moment(this.filters.dates[0]).format('YYYY-MM-DD')
        } else {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD')
          search.end_date = moment(this.filters.dates[1]).format('YYYY-MM-DD')
        }
      }
      if (this.filters.city_id) {
        search.city_id = this.filters.city_id
      }
      if (this.filters.club_id) {
        search.club_id = this.filters.club_id
      }
      if (this.filters.status) {
        if (this.filters.status === 'created_with_payment') {
          search.paid = false
          search.status = 'created'
        }
        search.status = this.filters.status
      }
      return search
    },
    handleClick(item) {
      this.$router.push({name: 'schedule.show', params: {id: item.id, club_id: item.club_id, schedule: item}})
    },
    loadSchedules() {
      bus.$emit('show-loader')
      this.isLoadingData = true
      this.data.list.splice(0)
      const data = this.prepareSearchData()
      scheduleService.getMySchedules(data)
          .then(response => {
            this.data.list = [...response]
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isLoadingData = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    showPayment(schedule) {
      this.transaction = {...schedule.transactions[0]}
      this.showPixModal = true
    }
  }
}
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.schedule.components.list.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex md4 xs12>
            <v-menu ref="filtersStartDate"
                    :close-on-content-click="false"
                    v-model="showModalDatePicker"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                    :label="$t('app.domains.schedule.components.schedule.list.filters.dateLabel')"
                    :placeholder="$t('app.domains.schedule.components.schedule.list.filters.dateLabel')"
                    readonly
                    v-model="filtersDates"
                    class="v-field--hide-details"
                    clearable
                    v-on="on">
                  <template v-slot:prepend>
                    <i class="fas fa-calendar-alt"></i>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.dates"
                             range
                             no-title
                             color="primary"
                             scrollable
                             :selected-items-text="filtersDates"
                             locale="pt-br"/>
            </v-menu>
          </v-flex>
          <v-flex md2 xs12>
            <v-select :items="dependencies.cities"
                      v-model="filters.city_id"
                      :loading="isLoadingCities"
                      :label="$t('components.main.sidebar.selectCity.label')"
                      :placeholder="$t('app.texts.selectSinglePlaceholder')"
                      :no-data-text="$t('components.main.sidebar.selectCity.emptyList')"
                      item-text="name_with_state"
                      item-value="id"
                      clearable
                      hide-details
                      @change="selectCity"/>
          </v-flex>
          <v-flex md2 xs12>
            <v-select :items="dependencies.clubs"
                      v-model="filters.club_id"
                      :loading="isLoadingClubs"
                      :label="$t('components.main.sidebar.selectClub.label')"
                      :placeholder="$t('app.texts.selectSinglePlaceholder')"
                      :no-data-text="$t('components.main.sidebar.selectClub.emptyList')"
                      item-text="name"
                      item-value="id"
                      clearable
                      hide-details
                      @change="selectClub"/>
          </v-flex>
          <v-flex md2 xs12>
            <v-select :items="dependencies.schedule_status"
                      v-model="filters.status"
                      :label="$t('app.domains.schedule.components.schedule.list.filters.statusLabel')"
                      :placeholder="$t('app.domains.schedule.components.schedule.list.filters.statusLabel')"
                      :no-data-text="$t('components.main.sidebar.selectClub.emptyList')"
                      item-text="name"
                      item-value="id"
                      clearable
                      hide-details/>
          </v-flex>
          <v-flex md1 xs12>
            <v-btn color="secondary" class="mt-2"
                   :title="$t('app.domains.schedule.components.schedule.list.filters.filterBtn')"
                   @click="loadSchedules">
              <i class="fas fa-filter"></i>
              <span>{{ $t('app.domains.schedule.components.schedule.list.filters.filterBtn') }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-list two-line
              class="schedule-list v-list-no-crop"
              v-if="!isLoadingData && data.list.length > 0">
        <template v-for="(item, index) in data.list">
          <v-list-item :key="`${item.id}-${item.start_datetime}`">
            <v-list-item-content @click="handleClick(item)">
              <v-list-item-title style="font-size: 15px;">
                <schedule-status :status="item.status"
                                 :transactions="item.transactions"
                                 :paid="item.paid"/>
                <v-chip :value="true"
                        color="blue"
                        small
                        outlined>
                  {{ item.court.name }}
                </v-chip>
                <div class="mt-1">
                  {{ item.start_datetime | dateTimeEnToBr }}h até {{ item.end_datetime | timeEnToBr }}h
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.court.club.name || '' }} - {{ item.court.club.city.name_with_state || '' }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text class="text-center">
                <div v-if="item.is_lesson" class="mb-1">
                  <v-chip :value="true"
                          small
                          outlined
                          :class="[`schedule-list-type-${item.type}`]">
                    {{
                      item.type === 'lesson'
                        ? $t('app.domains.schedule.components.list.table.items.lesson')
                        : $t('app.domains.schedule.components.list.table.items.fixedLesson')
                    }}
                  </v-chip>
                </div>
                <div v-else-if="item.fixed" class="mb-1">
                  <v-chip :value="true"
                          color="blue"
                          small
                          outlined>
                    {{ $t('app.domains.schedule.components.list.table.items.fixedSchedule') }}
                  </v-chip>
                </div>
                <v-chip :value="true"
                        color="secondary"
                        small
                        outlined>
                  {{
                    item.client && item.client.user_id === currentUser.id
                        ? $t('app.domains.schedule.components.list.table.items.responsible')
                        : $t('app.domains.schedule.components.list.table.items.participant')
                  }}
                </v-chip>
                <div v-if="item.transactions.length && !item.paid && item.status === 'created'"
                     class="mt-1">
                  <v-btn small ripple class="primary"
                         @click="showPayment(item)">
                    <i class="fas fa-dollar-sign"></i>
                    <span class="ml-1">{{ $t('app.domains.schedule.components.list.table.items.paymentBtn') }}</span>
                  </v-btn>
                </div>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < data.list.length" :key="index * 50 + 1"/>
        </template>
      </v-list>
      <empty-list v-if="!isLoadingData && data.list.length === 0"
                  :message="$t('app.domains.schedule.components.list.emptyListMessage')"/>
      <template v-if="isLoadingData">
        <loading-content :placeholders="3"/>
      </template>
    </v-card>
    <transaction-payment-modal v-if="showPixModal"
                               :transaction="transaction"
                               :show.sync="showPixModal"/>
  </div>
</template>
