export default {
  'pt_BR': {
    app: {
      texts: {
        localeText: 'Idioma',
        yes: 'Sim',
        no: 'Não',
        success: 'Sucesso',
        warning: 'Atenção',
        error: 'Erro',
        back: 'Voltar',
        backLogin: 'Voltar para o Login',
        close: 'Fechar',
        save: 'Salvar',
        saveData: 'Salvar Dados',
        dataSaved: 'Os dados foram salvos com sucesso',
        change: 'Alterar',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        sendData: 'Enviar Dados',
        selectSinglePlaceholder: 'Selecione...',
        selectPlaceholder: 'Selecione uma opção...',
        selectEmptyMessage: 'Nenhuma opção disponível no momento',
        listEmptyMessage: 'Nenhum registro encontrado',
        defaultLoading: 'Carregando dados',
        confirmActionQuestion: 'Deseja realmente realizar essa ação?',
        confirmActionText: 'Sim, continuar',
        routePermissionDenied: 'Parece que você tentou acessar uma página restrita',
        apiHttpSessionExpired: 'Sua sessão expirou, faça login novamente',
        apiHttpError: 'Ocorreu uma falha ao realizar essa operação, tente novamente mais tarde',
        apiHttpTimeout: 'A sua solicitação está demorando muito, tente novamente mais tarde',
        apiHttpMaintenance: 'O sistema está em processo de atualização, por favor tente novamente mais tarde',
      },
      domains: {
        auth: {
          routes: {
            login: {
              path: '/login/:hash?',
              title: 'Login'
            },
            trouble: {
              path: '/login/recuperar',
              title: 'Problemas ao acessar minha conta'
            }
          },
          components: {
            trouble: {
              pageTitle: 'Problemas ao acessar minha conta',
              recoveryAccessValidationMessage: 'Preencha o seu E-mail / Celular',
              recoveryAccessToEmailMessage: 'Enviamos um email com um link para recuperar o acesso da tua conta',
              recoveryAccessToPhoneMessage: 'Enviamos um SMS com um link para recuperar o acesso da tua conta',
              label: 'Selecione uma opção',
              recoveryOptionsText: {
                forgetPassword: {
                  title: 'Recuperar Senha',
                  text: 'Perdi minha senha e desejo criar uma nova',
                },
                magicLink: {
                  title: 'Link de Acesso Rápido',
                  text: 'Quero receber um link de login rápido',
                },
                resendActivationCode: {
                  title: 'Reenviar Link de Ativação',
                  text: 'Preciso ativar minha conta',
                }
              },
              recoveryTextLabel: 'Seu E-mail ou Celular'
            },
            login: {
              pageTitle: '',
              defaultLoginMessage: 'Estamos verificando seus dados',
              facebookAuth: 'Entrar com Facebook',
              googleAuth: 'Entrar com Google',
              orLoginDataMessage: 'Ou entre com seus dados',
              form: {
                loginLabel: 'E-mail / Celular / CPF / DNI / CI',
                passwordLabel: 'Digite sua Senha',
                loginBtn: 'Entrar'
              },
              recoveryAccessBtn: 'Problemas ao acessar minha conta',
              userRegisterBtn: 'Criar meu cadastro',
              accessWithoutAuth: 'Acesso sem cadastro'
            }
          }
        },
        payment: {
          routes: {
            index: {
              path: '/payment/:hash?',
              title: 'Pagamento'
            }
          },
        },
        common: {
          routes: {
            termsConditions: {
              path: '/termos-e-condicoes',
              title: 'Termos e Condições'
            },
            privacyPolicies: {
              path: '/politicas-de-privacidade',
              title: 'Politicas de Privacidade'
            },
            contact: {
              path: '/fale-conosco',
              title: 'Fale Conosco'
            }
          },
          components: {
            termsConditions: {
              pageTitle: 'Termos e Condições'
            },
            privacyPolicies: {
              pageTitle: 'Politicas de Privacidade'
            },
            contact: {
              pageTitle: 'Fale Conosco'
            }
          }
        },
        home: {
          routes: {
            home: {
              path: '/',
              title: 'Gripo'
            }
          },
          components: {
            pageTitle: '',
            scheduleMessage: 'Procurando um horário para jogar? Aqui você encontra os clubes e horários disponíveis!',
            scheduleBtn: 'Reservar Horário',
            searchingStagesMessage: 'Buscando torneios',
            availableStagesText: 'Próximos Torneios',
            filterText: 'Filtrar',
            registrationOpenedText: 'Inscrições Abertas',
            finishedStagesText: 'Torneios Finalizados',
            finishedStagesSubText: 'Acompanhe os resultados dos torneios passados',
            stageCardDatesFromText: 'DE',
            stageCardDatesToText: 'ATÉ',
            stageCoverText: 'Cartaz/Banner',
            contactText: 'Contato',
            newStageRegistrationBtn: 'Inscrição',
            registrationClosedText: 'Inscrições Encerradas',
            showStageRegistrations: 'Inscritos',
            showStageGroups: 'Chaves',
            showStageMatches: 'Jogos',
            searchingAvailableStagesMessage: 'Estamos buscando os torneios disponíveis...',
            availableStagesNotFoundMessage: 'Atualmente não existem torneios disponíveis',
            availableStagesWithFiltersNotFoundMessage: 'Não encontramos torneios com os filtros selecionados, verique os filtros selecionados',
            availableStagesNotFoundDoRegisterMessage: 'Você pode aproveitar e fazer o seu cadastro enquanto não estamos com torneios disponíveis!',
            doRegisterBtn: 'Cadastrar-se',
            hasAccountBtn: 'Já tenho cadastro',
            contactCardTitle: 'Precisa de ajuda?',
            contactCardText: 'Entre em contato pelo nosso email caso precise de algum suporte ou esteja com alguma dúvida sobre o app',
            clubCardTitle: 'Deseja cadastrar seu clube no Gripo?',
            clubCardText: 'Solicite o cadastro de teste pelo nosso site abaixo e aproveite o melhor e maior sistema de gestão esportiva do Brasil',
            filters: {
              yearLabel: 'Ano',
              typeLabel: 'Esporte',
              countryLabel: 'País',
              stateLabel: 'Estado/Província',
              cityLabel: 'Cidade',
            }
          }
        },
        registration: {
          routes: {
            store: {
              path: '/torneio/:slug/inscricao',
              title: 'Nova Inscrição'
            },
            index: {
              path: '/inscricoes',
              title: 'Minhas Inscrições'
            }
          },
          components: {
            form: {
              pageTitle: 'Nova Inscrição',
              form: {
                stageLabel: 'Torneio',
                playerClassLabel: 'Escolha a Categoria',
                playerClassSelectedLabel: 'Categoria',
                countryLabel: 'País',
                cityLabel: 'Cidade',
                cityTypeLabel: 'Digite o nome da cidade',
                citiesEmptyList: 'Cidades não encontradas',
                paymentPairText: 'Valor da Inscrição por Atleta',
                paymentUniqueText: 'Valor da Inscrição da Dupla',
                registrationLabel: 'Valor',
                loadingAmountLabel: 'Carregando valor da inscrição...',
                playerTitle: 'Atleta {number}',
                playerTitleSingle: 'Dados do Atleta',
                cpfLabel: 'CPF',
                cpfEqualMessage: 'O CPF do atleta 1 é igual ao do atleta 2',
                ciLabel: 'CI',
                dniLabel: 'DNI',
                firstNameLabel: 'Nome',
                firstNamePlaceholder: 'Nome',
                lastNameLabel: 'Sobrenome',
                lastNamePlaceholder: 'Sobrenome',
                emailLabel: 'E-mail',
                emailPlaceholder: 'E-mail',
                phoneLabel: 'Celular',
                phonePlaceholder: 'Celular',
                sexLabel: 'Sexo',
                sexPlaceholder: 'Sexo',
                shirtLabel: 'Camiseta',
                shirtTypeLabel: 'Tipo de Camiseta',
                shirtTypePlaceholder: 'Tipo de Camiseta',
                shirtSizeLabel: 'Tamanho da Camiseta',
                shirtSizePlaceholder: 'Tamanho da Camiseta',
                amountLabel: 'Valor da Inscrição',
                amountTotalLabel: 'Valor da Inscrição',
                amountDiscountLabel: 'DESCONTO',
                amountFinalLabel: 'Valor Total a Pagar',
                couponHeaderLabel: 'Cupom de Desconto',
                couponLabel: 'Insira o Cupom',
                couponApplyLabel: 'Aplicar CUPOM',
                contactTitle: 'Dúvidas/Contato',
                registrationBtn: 'Confirmar Inscrição',
                registrationFinished: {
                  titleSuccess: 'Deu tudo certo até agora! Boa sorte no torneio 💪🏻',
                  titlePaymentExpired: 'Seu pagamento expirou, realize novamente a inscrição!',
                  contact: '<strong>Em caso de dúvidas:</strong> {contact}',
                  btnShowMyRegistrations: 'Ver minha inscrição',
                  btnGoToPayment: 'Ir para o Pagamento',
                  btnTryAgain: 'Tentar novamente',
                },
                registrationWithoutPartner: 'Inscrição sem parceiro(a)',
              },
              impediments: {
                title: 'Impedimentos',
                message: 'Você só pode selecionar {value} impedimento(s) nesse torneio'
              },
              regulation: {
                title: 'Confira o regulamento do torneio',
                btnTitle: 'Abrir Regulamento',
                btnAction: 'Clique Aqui'
              },
              sponsors: {
                masterText: 'Patrocínio Master',
                othersText: 'Outros Patrocinadores'
              },
              confirmationDialog: {
                title: 'Confirmação de Inscrição'
              },
              registrationsClosedText: 'As inscrições para esse torneio não estão disponíveis'
            },
            list: {
              pageTitle: 'Minhas Inscrições',
              emptyListMessage: 'Não foram encontradas inscrições',
              cancelScheduleMessage: 'Deseja realmente cancelar a sua inscrição?',
              filters: {
                cityLabel: 'Cidade',
                dateLabel: 'Período',
                statusLabel: 'Status',
                filterBtn: 'Filtrar'
              },
              table: {
                headers: {
                  createdAt: 'Data',
                  stage: 'Torneio',
                  playerClass: 'Categoria',
                  points: 'Pontos',
                  amount: 'Valor',
                  status: 'Status',
                  payment: 'Pagamento',
                },
                items: {
                  actions: {
                    cancelBtn: 'Cancelar Inscrição'
                  }
                }
              },
            }
          }
        },
        order: {
          routes: {
            index: {
              path: '/comandas',
              title: 'Comandas'
            }
          },
          components: {
            list: {
              pageTitle: 'Comandas',
              clubNotSelected: 'Selecione um clube para buscar as suas comandas',
              emptyListMessage: 'Não foram encontrados clubes',
              generateTransactionMessage: 'Deseja realmente realizar o pagamento das comandas selecionadas?',
              filters: {
                clubLabel: 'Clube',
                dateLabel: 'Período',
                filterBtn: 'Filtrar'
              },
              table: {
                headers: {
                  club: 'Clube',
                  due_date: 'Vencimento',
                  description: 'Descrição',
                  amount: 'Valor (R$)',
                  products: 'Produtos',
                  to_pay: 'Pagar',
                },
                items: {
                  actions: {
                    showBtn: 'Detalhes',
                    payBtn: 'Gerar PIX para Pagamento',
                  }
                }
              },
              generatePaymentBtn: 'Pagar com PIX',
              clearSelectionBtn: 'Limpar Seleção',
              totalLabel: 'Valor Total'
            },
            productsTable: {
              title: 'Produtos Consumidos',
              table: {
                headers: {
                  description: 'Produto',
                  quantity: 'Qtde.',
                  price: 'Valor (R$)'
                }
              }
            }
          }
        },
        schedule: {
          routes: {
            daily: {
              path: '/reservar/:slug?/:hash?',
              title: 'Reservar Horário'
            },
            invite: {
              path: '/convite-para-partida/:uuid',
              title: 'Convite para Partida'
            },
            index: {
              path: '/minhas-reservas',
              title: 'Reservas/Partidas'
            },
            show: {
              path: '/reserva/:id/:club_id?',
              title: 'Reserva'
            },
            invites: {
              path: '/meus-convites',
              title: 'Convites'
            }
          },
          components: {
            invite: {
              pageTitle: 'Convite para Partida',
              data: {
                invitePlayerText: 'Atleta',
                dateText: 'Data',
                courtText: 'Quadra',
                clubText: 'Clube',
                inviteAcceptBtn: 'Aceitar',
                declineAcceptBtn: 'Declinar',
              }
            },
            invites: {
              pageTitle: 'Convites',
              emptyListMessage: 'Você não possui convites',
              table: {
                headers: {
                  datetime: 'Data/Hora',
                  club: 'Clube',
                  city: 'Cidade',
                  court: 'Quadra',
                  status: 'Situação'
                },
                items: {
                  responsible: 'Responsável',
                  participant: 'Participante',
                }
              },
            },
            list: {
              pageTitle: 'Minhas Reservas/Partidas',
              emptyListMessage: 'Você ainda não possui reservas/partidas',
              table: {
                headers: {
                  datetime: 'Data/Hora',
                  club: 'Clube',
                  city: 'Cidade',
                  court: 'Quadra',
                  status: 'Situação'
                },
                items: {
                  responsible: 'Responsável',
                  participant: 'Participante',
                  fixedSchedule: 'Horário Fixo',
                  lesson: 'Aula Avulsa',
                  fixedLesson: 'Aula Fixa',
                  status: {
                    created: 'Pendente',
                    created_with_payment: 'Aguardando Pgto.',
                    paid: 'Pago',
                    scheduled: 'Confirmada',
                    scheduled_with_payment: 'Paga/Confirmada',
                    canceled: 'Cancelada',
                  },
                  paymentBtn: 'Pagar',
                }
              }
            },
            schedule: {
              pageTitle: 'Reservar Horário',
              emptyListTitle: 'Esse clube não possui horários disponíveis nessa data',
              emptyListMessage: 'Selecione outra data ou clube',
              emptyListMessageNoClub: 'Não foi possivel encontrar quadras disponíveis nesse clube, selecione um clube ou cidade diferentes',
              openGlobalFilters: 'Alterar Filtros',
              clubPageSubTitleLabel: 'Clube',
              currentDateLabel: 'Data da Reserva',
              multipleScheduleBtn: 'Reservar Horários Selecionados',
              multipleScheduleClearBtn: 'Limpar Seleção',
              multipleSelectionMaxCountErrorMessage: 'Você não pode selecionar mais que {count} horário(s)',
              multipleSelectionErrorMessage: 'Você só pode reservar horários de uma mesma quadra',
              multipleSelectionSequenceErrorMessage: 'Você não pode ter horários vazios entre as reservas',
              membersScheduleText: 'Entre em contato com o clube para mais informações sobre os horários exclusivos',
              selectedCoachText: 'Selecione um(a) professor(a) para continuar com o agendamento',
              list: {
                filters: {
                  dateLabel: 'Data da Reserva/Partida',
                  statusLabel: 'Status',
                  filterBtn: 'Filtrar'
                },
                items: {
                  courtPriceText: 'Valor',
                  scheduleBtn: 'Reservar',
                  scheduleLessonBtn: 'Agendar Aula',
                  scheduledText: 'Horário Reservado',
                  onlinePaymentText: 'Pague Online',
                  selectedCoachText: 'Selecione um(a) professor(a) para visualizar o valor da aula',
                  scheduleUnavailableText: 'Horário não disponível para reservas',
                  lessonUnavailableText: 'Horário não disponível para reservas de aula',
                  lessonCoachUnavailableText: 'Professor(a) não disponível nesse horário',
                }
              },
              dialog: {
                title: 'Reserva de Horário',
                form: {
                  timeText: 'Relógio',
                  clubText: 'Clube',
                  courtText: 'Quadra',
                  dateText: 'Data',
                  hourText: 'Horário',
                  hourBetweenText: 'até',
                  courtPriceText: 'Valor da Reserva',
                  coachText: 'Professor(a)',
                  sportText: 'Selecione o esporte',
                  notesLabel: 'Observações',
                }
              }
            },
            show: {
              pageTitle: 'Dados da Reserva',
              cancelScheduleMessage: 'Deseja realmente cancelar essa reserva?',
              tabs: {
                data: {
                  title: 'Informações',
                  dateText: 'Data',
                  courtText: 'Quadra',
                  clubText: 'Clube',
                  cityText: 'Cidade',
                  amountPaidText: 'Valor Pago',
                  paidDateTest: 'Pago em',
                  cancelScheduleBtn: 'Cancelar Reserva',
                  paymentBtn: 'Pagar Reserva',
                  confirmedPlayersTitle: 'Jogadores Confirmados',
                  confirmedPlayersEmptyMessage: 'A reserva ainda não possui jogadores confirmados',
                  invitedPlayersTitle: 'Jogadores Convidados',
                  invitedPlayersEmptyMessage: 'Aproveite e convide seus amigos para a partida',
                  cancelLessonBtn: 'Cancelar Aula Avulsa',
                  cancelLessonFixedBtn: 'Cancelar Aula Fixa',
                  teacherText: 'Professor(a)',
                },
                invite: {
                  title: 'Convidar Amigos',
                  searchUserPlaceHolder: 'Pesquisar jogadores...',
                  inviteBtn: 'Convidar',
                  emptyListSearchBy: 'Pesquise pelo nome, telefone, email ou cpf',
                  scheduleFullTitle: 'Reserva completa',
                  scheduleFullMessage: '4 jogadadores já confirmaram presença no jogo',
                }
              }
            }
          }
        },
        stage: {
          routes: {
            show: {
              path: '/torneio/:slug',
              title: 'Torneio'
            },
            pairs: {
              path: 'inscritos/:player_class?',
              title: 'Torneio / Inscritos'
            },
            groups: {
              path: 'chaves/:player_class?',
              title: 'Torneio / Chaves'
            },
            matches: {
              path: 'jogos/:date?',
              title: 'Torneio / Jogos'
            },
            list: {
              path: '/torneios/anteriores',
              title: 'Torneios Anteriores'
            }
          },
          components: {
            pairs: {
              registrationStatusCreated: 'Inscrição Pendente',
              registrationStatusApproved: 'Inscrição Confirmada',
              registrationStatusQueue: 'Fila de Espera',
              subtitleText: 'Legenda',
              pairsLoadingTitle: 'Carregando lista de inscritos...',
              pairsNotAvailableTitle: 'Esse torneio ainda não possui inscritos',
              pairsNotAvailableMessage: 'Faça sua inscriçao clicando no botão abaixo',
            },
            groups: {
              groupText: 'Chave',
              groupsNotAvailableTitle: 'As chaves/eliminatórias desse torneio ainda não estão disponíveis',
              groupsLoadingTitle: 'Carregando grupos/eliminatórias...',
              matchesText: 'Jogos',
              eliminatoryMatchesText: 'Jogos Eliminatórios',
              matchWithoutDatetimeText: 'Horário a definir',
              table: {
                pairName: 'Dupla',
                singleName: 'Atleta',
                wins: 'Vitórias',
                setsBalance: 'Saldo Sets',
                games: 'Saldo Games'
              }
            },
            matches: {
              searchMatchesOfPlayer: 'Buscar jogador(a)...',
              matchesNotAvailableTitle: 'Não foram encontrados jogos disponíveis nessa data',
              matchesLoadingTitle: 'Carregando jogos...',
              matchesPlayerNotFound: 'Não encontramos jogos desse atleta',
            },
            list: {
              pageTitle: 'Torneios Anteriores',
              emptyListMessage: 'Não foram encontrados torneios',
              showFiltersText: 'Mostrar Filtros',
              cityLabel: 'Cidade',
              yearLabel: 'Ano'
            }
          }
        },
        user: {
          routes: {
            register: {
              path: '/cadastro',
              title: 'Cadastre-se'
            },
            confirmPhone: {
              path: '/confirmar-celular/:phone?/:code?',
              title: 'Confirmar Celular'
            },
            activateAccount: {
              path: '/ativar-conta/:hash?',
              title: 'Ativar Conta'
            },
            confirmAccount: {
              path: '/confirmar-conta/:hash?',
              title: 'Confirmar Conta'
            },
            show: {
              path: '/minha-conta',
              title: 'Minha Conta'
            },
            friends: {
              path: '/meus-amigos',
              title: 'Meus Amigos'
            }
          },
          components: {
            confirmPhone: {
              checkCodeMessage: 'Estamos verificando o código',
              smsConfirmationText: 'Enviamos um SMS para o celular informado com o código de confirmação',
              form: {
                phoneLabel: 'Celular',
                confirmationCodeLabel: 'Código de Confirmação',
                confirmBtn: 'Confirmar Cadastro',
              }
            },
            activateAccount: {
              checkActivation: 'Estamos ativando sua conta',
            },
            confirmAccount: {
              checkConfirmation: 'Estamos confirmando sua conta',
            },
            form: {
              pageTitle: 'Minha conta',
              invalidCPFMessage: 'CPF inválido',
              avatarText: 'Meu Avatar',
              updateAvatarBtn: 'Alterar Avatar',
              updatePasswordBtn: 'Alterar Senha',
              form: {
                countryLabel: 'País',
                cityLabel: 'Cidade',
                firstNameLabel: 'Nome',
                lastNameLabel: 'Sobrenome',
                emailLabel: 'E-mail',
                phoneLabel: 'Celular',
                cpfLabel: 'CPF',
                ciLabel: 'CI',
                dniLabel: 'DNI',
                birthDateLabel: 'Data de Nascimento',
                sexLabel: 'Sexo',
                sideLabel: 'Lado de Preferência',
              }
            },
            friends: {
              pageTitle: 'Meus Amigos',
              tabs: {
                friends: {
                  title: 'Amigos',
                  searchFriendsPlaceholder: 'Pesquisar amigos...',
                  inviteSentText: 'Convite enviado',
                  emptyListMessage: 'Você ainda não possui amigos',
                },
                requests: {
                  title: 'Solicitações',
                  acceptInviteBtn: 'Aceitar',
                  emptyListMessage: 'Não existem solicitações pendentes',
                },
                search: {
                  title: 'Procurar',
                  searchUserPlaceholder: 'Pesquisar usuários...',
                  inviteBtn: 'Convidar',
                  emptyListMessage: 'Pesquise por nome, telefone ou e-mail',
                }
              }
            },
            register: {
              registerLoadingMessage: 'Estamos verificando seus dados',
              form: {
                countryLabel: 'País',
                nameLabel: 'Nome Completo',
                emailLabel: 'E-mail',
                phoneLabel: 'Celular',
                cpfLabel: 'CPF',
                ciLabel: 'CI',
                dniLabel: 'DNI',
                passwordLabel: 'Senha',
                storeUserBtn: 'Finalizar Cadastro',
              }
            },
            qrCodeReader: {
              onDecode: {
                success: {
                  title: 'Seu QR Code foi validado com sucesso',
                },
              },
              onInit: {
                error: {
                  title: 'Aconteceu um erro ao iniciar a câmera, verifique as permissões ou atualize seu navegador',
                }
              },
              instruction: 'Aponte a câmera para o QR Code',
            }
          }
        }
      },
      data: {
        locale: {
          portuguese: 'Português',
          spanish: 'Espanhol'
        },
        sex: {
          male: 'Masculino',
          female: 'Feminino',
          notInformed: 'Não informar',
        },
        side: {
          left: 'Revés',
          right: 'Drive',
          both: 'Ambos',
        },
        schedule_status: {
          created: 'Pendente',
          created_with_payment: 'Aguardando Pgto.',
          paid: 'Pgto.',
          scheduled: 'Confirmada',
          canceled: 'Cancelada',
        },
        shirt_type: {
          standard: 'Normal',
          standard_female: 'Feminina',
          tight: 'Baby Look',
        },
        registration_status: {
          created: 'Aguardando Confirmação',
          created_with_payment: 'Aguardando Pgto',
          paid: 'Pago',
          approved: 'Confirmada',
          queue: 'Fila de Espera',
          canceled: 'Cancelada',
        }
      },
      validation: {
        required: 'Esse campo é obrigatório',
        invalidEmail: 'Email inválido',
        invalidCpf: 'CPF inválido',
        minLength: 'O número mínimo de caracteres para esse campo é: {minLength}',
        maxLength: 'O número máximo de caracteres para esse campo é: {maxLength}',
        sameAs: 'Os campos precisam ser iguais',
        invalidPhone: 'Telefone inválido'
      }
    },
    components: {
      general: {
        emptyList: {
          defaultTitle: 'Ops!',
          defaultMessage: 'Não encontramos o que você está procurando...'
        },
        enableNotification: {
          enableText: 'Desabilitar',
          disableText: 'Habilitar',
          notificationsText: '',
          notificationPermissionUpdateText: 'As permissões para receber notificações foram alteradas'
        },
        pageHeader: {
          homePageText: 'Página Inicial'
        },
        playerList: {
          defaultTitle: 'Jogadores',
          defaultEmptyListText: 'Sua reserva ainda não possui jogadores convidados'
        },
        transactionStatus: {
          created: 'Pendente',
          approved: 'Confirmada',
          queued: 'Fila de Espera',
          canceled: 'Cancelada',
          confirmed: 'Confirmada',
          refunded: 'Estornada',
          confirmedAt: 'Confirmada<br>Em: {pay_date}h',
          paidAt: 'Paga<br>Em: {pay_date}h',
          waitingPayment: 'Aguardando Pgto.',
          waitingConfirmation: 'Pendente',
          doPaymentBtn: 'Pagar'
        },
        transactionPayment: {
          text: 'Utilize o QRCode ou código PIX abaixo para realizar o pagamento',
          amountText: 'Valor',
          generateQrCodeText: 'Gerando QR Code...',
          pixCodeText: 'Código PIX',
          pixCopyCodeText: 'Copiar Código PIX'
        }
      },
      main: {
        app: {
          networkStatus: {
            activeTitle: 'Conexão ativa',
            activeMessage: 'Sua internet está funcionando',
            inactiveTitle: 'Conexão interrompida',
            inactiveMessage: 'Sua internet não parece estar funcionando'
          },
          updateAvailable: {
            text: 'Existe uma atualização disponível, para prosseguir utilize o botão abaixo para atualizar o sistema',
            textUpdated: 'O app foi atualizado, aproveite!',
            btnAction: 'Atualizar'
          },
          deviceVerification: {
            email: {
              text: 'Seu <strong>e-mail</strong> ainda não está confirmado',
              btnText: 'Enviar e-mail de confirmação'
            },
            phone: {
              text: 'Seu <strong>telefone</strong> ainda não está confirmado',
              btnCodeText: 'Já possuo um código',
              btnText: 'Enviar mensagem de confirmação'
            },
            modal: {
              title: 'Confirmação de Telefone',
              confirmCodeBtnText: 'Confirmar telefone',
              sendBtnText: 'Enviar mensagem',
              emailText: 'E-mail',
              phoneText: 'Telefone',
              channelText: 'Enviar via',
              codeText: 'Informe o código enviado',
            }
          }
        },
        header: {
          password: {
            changeText: 'Sua senha foi alterada',
            modal: {
              title: 'Alteração de Senha',
              currentPasswordLabel: 'Senha atual',
              newPasswordLabel: 'Nova senha',
              newPasswordConfirmationLabel: 'Digita a nova senha novamente',
              saveBtn: 'Alterar senha'
            }
          }
        },
        globalFilters: {
          title: 'Filtros Globais'
        },
        headerNotifications: {
          title: 'Notificações',
          emptyListMessage: 'Não existem notificações'
        },
        sidebar: {
          subTitleClubLabel: 'Clube',
          selectCity: {
            label: 'Cidade',
            emptyList: 'Cidades não disponíveis'
          },
          selectClub: {
            label: 'Clube',
            emptyList: 'Clubes não disponíveis'
          },
          selectSport: {
            label: 'Selecione o Esporte',
            emptyList: 'Esportes não disponíveis'
          },
          selectCoach: {
            label: 'Selecione um(a) Professor(a)',
            emptyList: 'Professores não encontrados'
          },
          selectType: {
            label: 'Agendar Aula',
          },
          menu: {
            sections: {
              clubs: 'Clubes',
              stages: 'Torneios',
              user: 'Usuário',
            },
            items: {
              main: 'Página Inicial',
              authLogin: 'Login',
              userRegister: 'Cadastrar-se',
              scheduleDaily: 'Reservar Quadra',
              scheduleIndex: 'Reservas/Partidas',
              scheduleInvites: 'Convites de Jogos',
              membershipIndex: 'Filiações',
              orderIndex: 'Comandas',
              registrationIndex: 'Minhas Inscrições',
              registrationStore: 'Nova Inscrição',
              stageList: 'Torneios Anteriores',
              userFriends: 'Amigos',
              userShow: 'Minha Conta',
              userQrCode: 'Validar QR Code',
              termsConditions: 'Termos e Condições',
              privacyPolicies: 'Politicas de Privacidade',
              contact: 'Fale Conosco',
              userLogout: 'Sair'
            }
          }
        }
      }
    }
  }
}
