import Api from '../common/api/Api'

export default class RegistrationService extends Api {
  constructor () {
    super({domain: 'registration'})
  }

  async save (data) {
    return this.post(data)
  }

  async cancel (id, data) {
    return this.post({route: 'cancel', id, data})
  }

  async getMyRegistrations (data) {
    return this.post({route: 'my', cache: 'myRegistrations', data})
  }

  async getPlayersAmount (data) {
    return this.post({route: 'players-amount', data})
  }

  async validateCoupon (data) {
    return this.post({route: 'validate-coupon', data})
  }
}
