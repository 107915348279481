<script>
import TransactionStatus from '../../../components/general/TransactionStatus'
import EmptyList from '../../../components/general/EmptyList'
import LoadingContent from '../../../components/general/placeholders/LoadingContent'
import TransactionPaymentModal from '../../../components/general/TransactionPaymentModal'

import DataService from '../../../services/common/data'
import RegistrationApiService from '../../../services/domains/Registration'
import CityApiService from '../../../services/domains/City'

import bus from '@utils/bus'

import moment from 'moment'
import * as notifyService from '@/app/services/common/notify'
import ClubService from "../../../services/domains/Club";
import TransactionPaymentStatus from "../../../components/general/TransactionPaymentStatus.vue";

const registrationService = RegistrationApiService.build({})
const cityService = CityApiService.build({})
const clubService = ClubService.build({})

export default {
  components: {TransactionPaymentStatus, LoadingContent, TransactionStatus, EmptyList, TransactionPaymentModal},
  data() {
    return {
      isLoadingData: true,
      isLoadingCities: true,
      isLoadingClubs: false,
      filters: {
        dates: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        city_id: '',
        club_id: '',
        status: ''
      },
      dependencies: {
        cities: [],
        clubs: [],
      },
      showModalDatePicker: false,
      data: {
        headers: [
          {text: this.$t('app.domains.registration.components.list.table.headers.createdAt'), value: 'created_at'},
          {text: this.$t('app.domains.registration.components.list.table.headers.stage'), value: 'stage_id'},
          {
            text: this.$t('app.domains.registration.components.list.table.headers.playerClass'),
            value: 'player_class_id',
            width: 150
          },
          {
            text: this.$t('app.domains.registration.components.list.table.headers.amount'),
            value: 'amount',
            align: 'right',
            width: 100
          },
          {
            text: this.$t('app.domains.registration.components.list.table.headers.status'),
            value: 'status',
            align: 'center'
          },
          {
            text: this.$t('app.domains.registration.components.list.table.headers.payment'),
            value: 'payment_status',
            align: 'center'
          },
          {
            text: this.$t('app.domains.registration.components.list.table.headers.points'),
            value: 'points',
            align: 'center'
          },
          {text: '', align: 'center'},
        ],
        list: [],
        footerProps: {
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Itens por página'
        }
      },
      transaction: null,
      showPixModal: false
    }
  },
  computed: {
    filtersDates: {
      get() {
        if (this.filters.dates.length > 0) {
          if (this.filters.dates.length === 1) {
            return moment(this.filters.dates[0]).format('DD/MM/YYYY')
          } else {
            return 'De ' + moment(this.filters.dates[0]).format('DD/MM/YYYY') + ' até ' + 'De ' + moment(this.filters.dates[1]).format('DD/MM/YYYY')
          }
        }
        return ''
      },
      set(value) {
        if (!value) {
          this.filters.dates.splice(0)
        } else {
          this.filters.dates = [...value]
        }
      }
    }
  },
  async mounted() {
    await this.getCities()
    this.getDependencies()
    this.loadRegistrations()
    if (this.$route.params.transaction !== undefined && this.$route.params.transaction !== null) {
      this.transaction = {...this.$route.params.transaction}
      setTimeout(() => {
        this.showPixModal = true
      }, 100)
    }
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'registration_status'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    getCities() {
      return new Promise(resolve => {
        this.isLoadingCities = true
        cityService.getAvailable({all:true}).then(response => {
          this.dependencies.cities = [...response]
          this.dependencies.clubs.splice(0)
          this.isLoadingCities = false
          resolve()
        })
      })
    },
    getClubs(cityId) {
      return new Promise(resolve => {
        this.isLoadingClubs = true
        const data = {city_id: cityId, all:true}
        clubService.getAvailable(data).then(response => {
          this.dependencies.clubs = [...response]
          this.isLoadingClubs = false
          resolve()
        })
      })
    },
    async selectCity(value) {
      this.dependencies.clubs.splice(0)
      if (value) {
        await this.getClubs(value)
      }
    },
    prepareSearchData() {
      const search = {}
      if (this.filters.dates.length > 0) {
        if (this.filters.dates.length === 1) {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD')
          search.end_date = moment(this.filters.dates[0]).format('YYYY-MM-DD')
        } else {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD')
          search.end_date = moment(this.filters.dates[1]).format('YYYY-MM-DD')
        }
      }
      if (this.filters.city_id) {
        search.city_id = this.filters.city_id
      }
      if (this.filters.club_id) {
        search.club_id = this.filters.club_id
      }
      if (this.filters.status) {
        search.status = this.filters.status
      }
      return search
    },
    loadRegistrations() {
      this.isLoadingData = true
      this.data.list.splice(0)
      const data = this.prepareSearchData()
      registrationService.getMyRegistrations(data)
          .then(response => {
            this.data.list = [...response]
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isLoadingData = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async cancelRegistration(item) {
      const confirm = await notifyService.confirm({title: this.$t('app.domains.registration.components.list.cancelScheduleMessage')})
      if (confirm) {
        bus.$emit('show-loader')
        this.isLoadingData = true
        const data = {
          tenant_id: item.tenant_id
        }
        registrationService.cancel(item.id, data)
            .then(() => {
              this.loadRegistrations()
            })
            .catch(e => {
              this.isLoadingData = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    }
  }
}
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.registration.components.list.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex md4 xs12>
            <v-menu ref="filtersStartDate"
                    :close-on-content-click="false"
                    v-model="showModalDatePicker"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                    :label="$t('app.domains.registration.components.list.filters.dateLabel')"
                    :placeholder="$t('app.domains.registration.components.list.filters.dateLabel')"
                    readonly
                    v-model="filtersDates"
                    class="v-field--hide-details"
                    clearable
                    v-on="on">
                  <template v-slot:prepend>
                    <i class="fas fa-calendar-alt"></i>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.dates"
                             range
                             no-title
                             color="primary"
                             scrollable
                             :selected-items-text="filtersDates"
                             locale="pt-br"/>
            </v-menu>
          </v-flex>
          <v-flex md2 xs12>
            <v-select :items="dependencies.cities"
                      v-model="filters.city_id"
                      :loading="isLoadingCities"
                      :label="$t('app.domains.registration.components.list.filters.cityLabel')"
                      :placeholder="$t('app.domains.registration.components.list.filters.cityLabel')"
                      :no-data-text="$t('components.main.sidebar.selectCity.emptyList')"
                      item-text="name_with_state"
                      item-value="id"
                      clearable
                      hide-details
                      @change="selectCity"/>
          </v-flex>
          <v-flex md2 xs12>
            <v-select :items="dependencies.clubs"
                      v-model="filters.club_id"
                      :loading="isLoadingClubs"
                      :label="$t('components.main.sidebar.selectClub.label')"
                      :placeholder="$t('app.texts.selectSinglePlaceholder')"
                      :no-data-text="$t('components.main.sidebar.selectClub.emptyList')"
                      item-text="name"
                      item-value="id"
                      clearable
                      hide-details/>
          </v-flex>
          <v-flex md2 xs12>
            <v-select :items="dependencies.registration_status"
                      v-model="filters.status"
                      :label="$t('app.domains.registration.components.list.filters.statusLabel')"
                      :placeholder="$t('app.domains.registration.components.list.filters.statusLabel')"
                      :no-data-text="$t('app.texts.listEmptyMessage')"
                      item-text="name"
                      item-value="id"
                      clearable
                      hide-details/>
          </v-flex>
          <v-flex md1 xs12>
            <v-btn color="secondary" class="mt-2"
                   :title="$t('app.domains.registration.components.list.filters.filterBtn')"
                   @click="loadRegistrations">
              <i class="fas fa-filter"></i>
              <span>{{ $t('app.domains.registration.components.list.filters.filterBtn') }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-data-table :headers="data.headers"
                    :items="data.list"
                    v-if="!isLoadingData && data.list.length > 0"
                    :no-data-text="$t('app.texts.listEmptyMessage')"
                    :class="[{'responsive-data-table': $vuetify.breakpoint.xs}]"
                    disable-sort
                    hide-default-footer
                    :footer-props="data.footerProps">
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <template v-if="!$vuetify.breakpoint.xs">
              <td>{{ item.created_at | dateTimeEnToBr }}h</td>
              <td>{{ item.stage_name }}</td>
              <td>{{ item.player_class_name }}</td>
              <td class="text-right">
                <span v-if="item.amount > 0">{{ item.amount | currencyEnToBr }}</span>
              </td>
              <td class="text-center">
                <transaction-status :status="item.status"/>
              </td>
              <td class="text-center">
                <transaction-payment-status :transaction="item.transaction_data"/>
              </td>
              <td class="text-center">
                <span v-if="item.stage_status === 'finished'">{{ item.points }}</span>
              </td>
              <td>
                <v-btn color="error"
                       small
                       v-if="item.status === 'created'"
                       :title="$t('app.domains.registration.components.list.table.items.actions.cancelBtn')"
                       @click="cancelRegistration(item)">
                  <i class="fas fa-times"></i>
                </v-btn>
              </td>
            </template>
            <template v-else>
              <td>
                <ul class="flex-content">
                  <li class="flex-item" data-label="Data">{{ item.created_at | dateTimeEnToBr }}h</li>
                  <li class="flex-item" data-label="Torneio">{{ item.stage_name }}</li>
                  <li class="flex-item" data-label="Categoria">{{ item.player_class_name }}</li>
                  <li class="flex-item" data-label="Valor">
                    <span v-if="item.transaction_data">{{ item.transaction_data.amount | currencyEnToBr }}</span>
                  </li>
                  <li class="flex-item" data-label="Status">
                    <transaction-status :status="item.status"/>
                  </li>
                  <li class="flex-item" data-label="Pagamento">
                    <transaction-payment-status :transaction="item.transaction_data"/>
                  </li>
                  <li class="flex-item" data-label="Pontos">{{ item.points }}</li>
                  <li class="flex-item" data-label="">
                    <v-btn color="error"
                           small
                           v-if="item.status === 'created'"
                           :title="$t('app.domains.registration.components.list.table.items.actions.cancelBtn')"
                           @click="cancelRegistration(item)">
                      <i class="fas fa-times"></i>
                    </v-btn>
                  </li>
                </ul>
              </td>
            </template>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <empty-list v-if="!isLoadingData && data.list.length === 0"
                  :message="$t('app.domains.registration.components.list.emptyListMessage')"/>
      <template v-if="isLoadingData">
        <loading-content :placeholders="3"/>
      </template>
    </v-card>
    <transaction-payment-modal :transaction="transaction" :show.sync="showPixModal"/>
  </div>
</template>
