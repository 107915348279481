<script>
export default {
  name: 'transaction-status',
  props: ['status'],
  computed: {
    chipStatus() {
      switch (this.status) {
        case 'created':
          return {
            label: this.$t('components.general.transactionStatus.created'),
            color: 'teal'
          }
        case 'approved':
          return {
            label: this.$t('components.general.transactionStatus.approved'),
            color: 'green'
          }
        case 'queued':
          return {
            label: this.$t('components.general.transactionStatus.queued'),
            color: 'teal'
          }
        case 'canceled':
          return {
            label: this.$t('components.general.transactionStatus.canceled'),
            color: 'red'
          }
        default:
          return {
            label: '',
            color: ''
          }
      }
    }
  }
}
</script>

<template>
  <div class="pt-1 pb-1">
    <v-chip outlined small :color="chipStatus.color">
      {{ chipStatus.label }}
    </v-chip>
  </div>
</template>
